import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Footer.css";
import { Layout, Row } from "../Layout/Layout";

const Footer = () => {
    return(
        <footer>
            <Layout>
                <Row wrap={"wrap"}>
                    <div className="footer--footer-column">
                        <div className="logo" style={{display: "flex", alignItems: "center"}}>
                            <img src="./assets/new-logo.svg" className="logo-svg" />
                        </div>
                        <span style={{marginTop: "4.2rem", color: "var(--yellow-color)"}}>Electricians serving The East End of Long Island</span>
                    </div>
                    <div className="footer--footer-column">
                        <Link to="/">Home</Link>
                        <Link to="/services">Services</Link>
                        <Link to="/about">About</Link>
                        <Link to="/contact">Contact</Link>
                    </div>
                    <div className="footer--footer-column">
                        <Link to="/services">Pool Wiring</Link>
                        <Link to="/services">Construction</Link>
                        <Link to="/services">Lighting Solutions</Link>
                        <Link to="/services">Home Automation Systems</Link>
                        <Link to="/services">Design Consultation</Link>
                        <Link to="/services">Audio / Video Services</Link>
                    </div>
                    <div className="footer--footer-column">
                        <span>
                            Contact
                        </span>
                        <span>
                            <a href="mailto:joe@vueelectric.com">joe@vueelectric.com</a>
                        </span>
                        <span>
                            <a href="tel:6312987127">(631) 298-7127</a>
                        </span>
                        <span style={{marginTop: "4rem"}}>
                            <a href="https://piscatello.com" rel="noopener noreferrer" target="_blank">Site development by <br />Piscatello Design Centre</a>
                        </span>
                    </div>
                </Row>
            </Layout>
        </footer>
    )
}

export default Footer;
